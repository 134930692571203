import { Dayjs } from 'dayjs';
import { TailwindColor } from './Color.type';
import { PatientTransactionItemType } from './PatientTransaction.type';
import { AppointmentWorkflow } from './Workflow.type';
import {
  BusinessRuleItemType,
  STRING_BOOLEAN_HASH,
} from '../constants/globals';
import { ChiroUpDayJsCommon } from '../constants/stringConstants';
import { ChiroUpJSON } from '../functions/ChiroUpJSON';
import { createNeverNullDayjs } from '../functions/time';

export interface AppointmentHistoryResult {
  totalCheckedIn: number;
  totalScheduledFuture: number;
  totalNoShow: number;
  totalCanceled: number;
  previousAppointment: {
    startTime: string | null;
    clinician: string | null;
    id: string | null;
  };
  nextAppointment: {
    startTime: string | null;
    clinician: string | null;
    id: string | null;
  };
}

export enum AppointmentStatuses {
  Scheduled = 'Scheduled',
  Canceled = 'Canceled',
  NoShow = 'No-Show',
  CheckedIn = 'Checked In',
  Deleted = 'Deleted',
}

export const DoNotCreateTransactionStatuses = {
  // [TODO TECH DEBT] It _may_ be necessary to have a transaction if an appointment
  //       is cancelled with a fee, so this allows it to be created. Unfortunately,
  //       it means it will just be deleted if there are no fee. Ideally, we would
  //       not create unnecessary transactions but the logic is very convoluted and
  //       this is a more reliable fix for now.
  // [AppointmentStatuses.Canceled]: true,
  [AppointmentStatuses.Deleted]: true,
};

export const AppointmentStatusOptions = Object.values(AppointmentStatuses).map(
  (status) => ({
    text: status,
    value: status,
  }),
);

export type DoubleClickApptArgs = {
  day: string;
  index: number;
  startTimeInterval: number;
  userId: string;
  isRoom?: boolean;
};

export enum AppointmentTypes {
  NewPatient = 'New Patient',
  ExistingPatient = 'Existing Patient',
}

export enum BookingMethods {
  StaffAssisted = 'Staff Assisted',
  SelfScheduled = 'Self Scheduled',
}

export const disableStatusesInPast = [
  AppointmentStatuses.CheckedIn,
  AppointmentStatuses.Canceled,
  AppointmentStatuses.NoShow,
];

export type AppointmentBase = {
  id: string;
  appointmentWorkflow?: AppointmentWorkflow;
  assessmentCodes?: any[];
  bookingMethod?: BookingMethods;
  bottom?: number;
  cancellationReason?: string;
  checkInTime?: number;
  clinicianId: string;
  clinicId: number;
  courtesyBilling?: boolean;
  createTrackCCSSubmission?: boolean;
  daysOfWeek?: string[];
  deleted?: boolean;
  deniedPatient?: boolean;
  disciplineId: number;
  displayValues: {
    patientName: string;
    clinicianName: string;
    treatmentName: string;
    disciplineName?: string;
    patientPhone?: string;
    patientEmail?: string;
    hasActiveInsurance?: boolean;
    deniedPatient?: boolean;
    pendingPatient?: boolean;
    caseType?: number;
    nextRequestedEncounterDate?: string;
    roomName?: string;
    profilePhoto?: string;
  };
  duration: number;
  encounterSigned?: boolean;
  endRecurring?: any;
  groupId?: number;
  hasBillingTransaction?: boolean;
  height?: number;
  isRoom?: boolean;
  left?: number;
  locationId: number;
  newPatient?: boolean;
  notes: string;
  optOutSMS?: boolean;
  patientId: string;
  patientName?: string;
  pendingPatient?: boolean;
  policies?: AppointmentInsuranceType[];
  recurringAppointmentId?: string;
  roomId?: number | null;
  services?: PatientTransactionItemType[];
  slots?: Appointment[];
  startTimeFormatted?: string;
  status: AppointmentStatuses;
  superBill?: boolean;
  surveySent?: string;
  timeOfDay?: string;
  top?: number;
  trackId?: number | null;
  treatmentId: number;
  treatmentName?: string;
  type?: AppointmentTypes;
  tz?: string;
  width?: number;
};

export type Appointment = AppointmentBase & {
  startTime: number;
  suppressAutomatedSurveys?: boolean;
  suppressReminders?: boolean;
};

export type AppointmentForUI = AppointmentBase & {
  inPast?: boolean;
  color?: TailwindColor;
  previous?: AppointmentForUI;
  latest?: AppointmentForUI;
  startTime: Dayjs;
  surveys?: string[];
  hasPurchase?: boolean | number;
};

export type AppointmentForEither = AppointmentBase & {
  startTime: number | Dayjs;
};

export type AppointmentInsuranceType = {
  ID: number;
  appointmentID?: string;
  billingPriority: number;
  clinicID: number;
  deductible: number;
  insuranceID: string;
  insuranceName: string;
  insuranceProgram?: string; // Used for claim creation.
  patientID?: string;
  disciplineID: number;
  coPay?: number;
  coInsurance?: number;
  payorID?: number;
  serviceAllowedAmounts?: {
    [key: string | number]: {
      payorID: number;
      allowedAmount?: number | string | null;
    };
  };
  inNetwork?: boolean;
  billable?: boolean;
  courtesyBilling?: boolean;
  updatePatientInsurance?: boolean;
  allowedAmount?: number | string | null;
  maxPerVisit?: number | string | null;
  payorId?: number;
};

export type ServicePayor = {
  ID?: number;
  allowedAmount?: number | string | null;
  code: string | number;
  payorID: number;
  insuranceID: string;
  billingRef: string;
  createdAt?: string;
  updatedAt?: string;
  deductible?: number;
  disciplineId?: number;
};

export type RecurringAppointmentData = {
  originalAppointmentUsedId?: string;
  duration?: number;
  patientId?: string;
  clinicianId?: string;
  treatmentId?: number;
  timestamps: {
    date: string;
    end: number;
    start: number;
  }[];
  displayValues?: {
    patientName: string;
    clinicianName: string;
    treatmentName: string;
  };
  locationId?: number;
};

export type CreateRecurringAppointmentsParams = {
  body: RecurringAppointmentsToSave;
  clinicId?: number;
  sessionId?: string;
  locationId?: number;
  notify?: boolean;
  originalAppointment?: AppointmentForUI;
};

export type RecurringAppointmentsToSave = {
  apptsToBook: ApptToBook[];
  patientId: string;
  locationId: number;
};

export type ApptToBook = {
  slots: RecurringAppointmentSlot[];
};

export type RecurringAppointmentSlot = {
  trackId?: number;
  clinicianId?: string;
  treatmentId?: number;
  start?: number;
  end?: number;
  roomId?: number;
  duration?: number;
  displayValues?: {
    patientName: string;
    clinicianName: string;
    treatmentName: string;
  };
};
export type RecurringAvailability = {
  treatmentId?: number;
  clinicianId?: string;
  patientId?: string;
  displayValues?: {
    patientName: string;
    clinicianName: string;
    treatmentName: string;
  };
  duration?: number;
  dataWithAllConflicts?: dataWithAllConflicts[];
};

export type dataWithAllConflicts = {
  id: number;
  conflicts: Appointment[];
  messages: {
    overrideMessage?: string;
    clinicLocationMessage?: string;
    providerMessage?: string;
  };
  slots?: {
    date?: string;
    end: number;
    start: number;
    treatmentId: number;
    clinicianId: string;
    roomId?: number;
    trackId?: number;
    roomName?: string;
    duration: number;
  }[];
  timestamp: { date: string; end: number; start: number };
};

export enum AppointmentTypes {
  Initial = 'initial',
  Interim = 'interim',
  Reevaluation = 'reevaluation',
}

export type AppointmentUser = {
  id: string;
  name: string;
  fname?: string;
  lname?: string;
  profileImage?: string;
  appointments: AppointmentForEither[];
  hours?: {
    start: number;
    end: number;
  }[];
  color?: string;
  isRoom?: boolean;
  overrides?: {
    date: string;
    start: string;
    end: string;
    color: string;
    name?: string;
    recurringId?: string;
    clinicianId?: string;
  }[];
};

export type Appointments = {
  [key: string]: {
    [key: string]: AppointmentUser;
  };
};

export type AvailableSlotsResponse = {
  // Date
  [key: string]: {
    // User ID
    [key: string]: AppointmentForUI[];
  };
};

export type NoShowCancelFees = {
  ID: number;
  name: string;
  type: string;
  structure: string;
  value: number;
  clinicId: number;
  deleted: number;
};

/**
 * This comprises the business rules to apply to see if we can submit
 * something from the UI or save something in the backend. Same rules
 * apply.
 *
 * @param param0
 * @returns
 */
const sanityCheckAppointment = ({
  appointment,
  primaryDisciplineOptions = [],
  tz = ChiroUpDayJsCommon.defaultTimezone,
  frontend = false,
  patientHasTracks = false,
  cancellingCheckInOrAppt = false,
}: {
  appointment: any;
  primaryDisciplineOptions?: any[];
  tz?: string;
  frontend?: boolean;
  patientHasTracks?: boolean;
  cancellingCheckInOrAppt?: boolean;
}) => {
  const issues: BusinessRuleItemType[] = [];
  // const issues: BusinessRuleItemType[] = [
  //   { text: 'Your breath stinks.' },
  //   { text: 'Your Mom is ugly.' },
  // ];

  const firstSlotForEachClinician = appointment?.slots?.reduce(
    (obj: { [key: string]: number }, slot: Appointment, idx: number) => {
      if (!(slot.clinicianId in obj)) {
        obj[slot.clinicianId] = idx;
      }
      return obj;
    },
    {} as { [key: string]: number },
  );

  if (!appointment) {
    issues.push({
      text: 'No appointment provided.',
    });
  }

  if (!appointment.startTime) {
    issues.push({
      text: 'No appointment date provided.',
    });
  }

  // If we are deleting do we need to check our sanity?
  if (appointment.deleted) {
    return issues;
  }

  const slotLength = appointment?.slots?.length ?? 1;

  if (!appointment?.slots || appointment.slots.length === 0) {
    issues.push({
      text: 'No time slots provided.',
    });
  }

  if (!appointment.patientId) {
    issues.push({
      text: 'No patient selected.',
    });
  }

  if (!appointment.clinicianId || appointment.clinicianId === '-1') {
    issues.push({
      text: 'No primary clinician selected.',
    });
  }

  if (!appointment.treatmentId) {
    issues.push({
      text: 'No primary treatment selected.',
    });
  }

  if (!appointment.disciplineId) {
    issues.push({
      text: 'No primary discipline selected.',
    });
  }

  const clone: Appointment[] = ChiroUpJSON.clone(
      appointment?.slots ?? [],
    ) as Appointment[],
    usedTreatmentIds = {} as { [key: string]: boolean },
    usedCliniciandIds = {} as { [key: string]: boolean };

  // Sorry JG, feel free to refactor!
  clone.forEach((c) => {
    usedCliniciandIds[c.clinicianId] = true;
    usedTreatmentIds[c.treatmentId] = true;
  });

  let minimumStartTime = 0,
    examinedCount = 1;
  while (clone.length > 0) {
    const slot: Appointment = clone.shift() as Appointment;
    if (!slot) continue;
    if (slot.startTime < minimumStartTime && !cancellingCheckInOrAppt) {
      issues.push({
        text: `Time slot #${examinedCount} @\u00A0${ChiroUpDayJsCommon.display.datetimeWithTz(
          slot.startTime,
          tz,
          tz,
          'h:mma',
        )} overlaps the previous time slot.`,
      });
    }
    minimumStartTime = slot.startTime + 1000 * 60 * slot.duration; // 30 minutes
    const required: string[] = [],
      clinicianOk = slot.clinicianId && slot.clinicianId !== '-1',
      roomOk = slot.roomId && String(slot.roomId) !== '-1';
    if (!clinicianOk && !roomOk) {
      required.push('clinician or room');
    }

    if (!slot.treatmentId) {
      required.push('treatment');
    }
    if (!slot.duration) {
      required.push('duration');
    }

    if (
      frontend &&
      slot?.clinicianId &&
      slot?.clinicianId !== '-1' &&
      firstSlotForEachClinician[slot.clinicianId] === examinedCount - 1 &&
      !slot?.trackId &&
      !slot?.createTrackCCSSubmission &&
      patientHasTracks
    ) {
      required.push('track or create track on CCS submission');
    }
    if (required.length === 1) {
      issues.push({
        text: `Time slot #${examinedCount} is missing ${required[0]}.`,
      });
    } else if (required.length === 2) {
      issues.push({
        text: `Time slot #${examinedCount} is missing ${required.join(
          ' and ',
        )}.`,
      });
    } else if (required.length > 2) {
      required[required.length - 1] = `and ${required[required.length - 1]}`;
      issues.push({
        text: `Time slot #${examinedCount} is missing ${required.join(', ')}.`,
      });
    }
    examinedCount++;
  }

  const which = [];
  if (appointment?.clinicianId && !usedCliniciandIds[appointment.clinicianId]) {
    which.push('clinician');
  }
  if (appointment?.treatmentId && !usedTreatmentIds[appointment.treatmentId]) {
    which.push('treatment');
  }
  if (
    appointment?.disciplineId &&
    primaryDisciplineOptions.length > 0 &&
    !primaryDisciplineOptions.find((d) => d.value === appointment.disciplineId)
  ) {
    which.push('discipline');
  }

  if (which.length === 1) {
    issues.push({
      text: `The primary value for ${which[0]} was empty or not used${
        slotLength === 1 ? '.' : ' in any time slots.'
      }`,
    });
  } else if (which.length > 1) {
    issues.push({
      text: `The following primary values were not used${
        slotLength === 1 ? ': ' : ' in any time slots: '
      } ${which.join(', ')}.`,
    });
  }

  return issues;
};

export const ApplyAppointmentBusinessRules = {
  uiBeforeCreate: sanityCheckAppointment,
  backendBeforeCreate: sanityCheckAppointment,
};

export type AppointmentSlot = {
  id: string;
  slotId: string;
  clinicId: number;
  patientId: string;
  clinicianId: string;
  disciplineId: number;
  treatmentId: number;
  duration: number;
  startTime: number;
};

/**
 * [Story # 7594941275] Super appointments.
 */
export type AppointmentGroupRow = {
  id: number;
  clinicId: number;
  type: number;
  patientId: string;
  clinicianId: string;
  disciplineId: number;
  treatmentId: number;
  notes?: string;
};
export const APPOINTMENT_GROUP_TABLE = 'AppointmentGroup';
export const APPOINTMENT_GROUP_ITEM_TABLE = 'AppointmentGroupItem';
export const APPOINTMENT_GROUP_LOG_TABLE = 'AppointmentGroupLog';

export const AppointmentGroupSchema = {
  columns: [
    { name: 'id', type: 'number', update: false },
    { name: 'clinicId', type: 'number', update: false },
    { name: 'type', type: 'number', update: false },
    { name: 'patientId', type: 'string', update: false },
    { name: 'clinicianId', type: 'string' },
    { name: 'disciplineId', type: 'number' },
    { name: 'treatmentId', type: 'number' },
    { name: 'notes', type: 'string' },
  ],
};
export const AppointmentGroupUpdateField =
  AppointmentGroupSchema.columns.reduce((a, col) => {
    a[col.name] = typeof col.update === 'boolean' ? col.update : true;
    return a;
  }, {} as STRING_BOOLEAN_HASH);

export type AppointmentGroupItemRow = {
  clinicId: number;
  groupId: number;
  itemId: string;
};

export enum AppointmentGroupLogType {
  Created = 1,
  Updated = 2, // This is for updating the PRIMARY fields on AppointmentGroupRow [TODO: Need to implement logging for this.]
  SoftDeleted = 3,
  DeletedItem = 4,
  AddedItem = 5,
}

export const AppointmentGroupLogTypeDisplay = {
  [AppointmentGroupLogType.Created]: { tc: 'Created', lc: 'created' },
  [AppointmentGroupLogType.Updated]: { tc: 'Updated', lc: 'updated' },
  [AppointmentGroupLogType.SoftDeleted]: {
    tc: 'Soft deleted',
    lc: 'soft deleted',
  },
  [AppointmentGroupLogType.DeletedItem]: {
    tc: 'Deleted',
    lc: 'deleted item',
  },
  [AppointmentGroupLogType.AddedItem]: { tc: 'Added item', lc: 'added item' },
};

export const appointmentGroupLogDisplayText = ({
  appointment,
  type,
}: {
  appointment: Appointment;
  type: AppointmentGroupLogType;
}) => {
  if (!appointment || !type) return null;
  const startTimeDayJs = createNeverNullDayjs({
      datetime: appointment.startTime,
      timezone: appointment?.tz,
    }),
    clinicianName = appointment?.clinicianId
      ? appointment?.displayValues?.clinicianName ??
        `- ${appointment.clinicianId} -`
      : null,
    treatmentName =
      appointment?.displayValues?.treatmentName ??
      `- ${appointment.treatmentId} -`,
    roomName = appointment?.roomId
      ? appointment?.displayValues?.roomName ?? `- ${appointment.roomId} -`
      : null,
    startAt = `${startTimeDayJs
      .tz(appointment?.tz)
      .format(ChiroUpDayJsCommon.format.time)}`;
  switch (type) {
    // Deleted time slot with ChiroUp Provider at 7:00pm for 60 minutes for treatment Orthopedic Massage
    case AppointmentGroupLogType.DeletedItem:
      return (
        [
          AppointmentGroupLogTypeDisplay[type].tc,
          appointment?.duration ?? '- na -',
          'minute time slot',
          clinicianName ? 'with' : 'in',
          clinicianName ?? roomName,
          'at',
          startAt,
          'for treatment',
          treatmentName,
        ].join(' ') + '.'
      );
    default:
      return null;
  }
};

export type AppointmentGroupLogRow = {
  id: number;
  clinicId: number;
  groupId: number;
  detail: any;
  type: AppointmentGroupLogType;
  timestamp: number;
};

export enum AppointmentGroupType {
  SuperAppointment = 1,
}
/*
 * /story/7594941275
 **/
